import * as React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { mq, colors } from '../common/styles';

const MainContainer = styled.div`
display: flex;
align-items: center;
// overlap into frame
margin: 0 -16px;
.slide-container {
  position: relative;
  flex: 1;
}
.slide {
  display: none;
  text-align: center;
  &.active {
    display: block;  
  }
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  padding: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  margin: 0 8px 0 0;
  /* On hover, add a black background color with a little bit see-through */
  &:hover {
    background-color: rgba(0,0,0,0.2);
    color: white; 
  }
}

/* Position the "next button" to the right */
.next {
  border-radius: 3px 0 0 3px;
  margin: 0 0 0 8px;
}
`;

const DotContainer = styled.div`
text-align: center;
padding: 8px;

.dot {
  cursor: pointer;
  height: 17px;
  width: 17px;
  margin: 0 4px;
  ${mq.tablet} {
    height: 15px;
    width: 15px;
    margin: 0 2px;
  }
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.4s ease;
  background-color: ${colors.gunmetal};
  &:hover {
    background-color: ${colors.gunmetallight};
  }
  &.active {
    background-color: ${colors.rust};
  }
}
`;

interface Props {
    items: any[];
    renderItem: ({ item: any }) => React.ReactNode;
}

export default class Slideshow extends React.Component<Props> {
  state = {
    minHeight: undefined,
    currentSlide: 0,
  };

  mainRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
      items: [],
      renderItem: () => null,
  };

  moveSlide (indexShift) {
    let next = this.state.currentSlide + indexShift
    if (next + 1 > this.props.items.length) {
        next = 0;
    } if (next < 0) {
        next = this.props.items.length - 1;
    }

    this.setState({
        currentSlide: next,
    });
  }

  setSlideIndex (index) {
      this.setState({currentSlide: index});
  }

  minHeightCheck() {
    const el = this.mainRef.current;

    if (!el) { return; }
    const { minHeight } = this.state;
    const { height } = el.getBoundingClientRect();

    if (height && (!minHeight || minHeight < height)) {
        this.setState({ minHeight: height });
    }
  }

  componentDidMount(): void {
      this.minHeightCheck();
  }
  componentDidUpdate(): void {
      this.minHeightCheck();
  }

  render () {
    const { items, renderItem } = this.props;
    const { currentSlide, minHeight } = this.state;

    return (
      <>
        <MainContainer ref={this.mainRef} style={{minHeight: minHeight}}>
          <a className="prev" onClick={() => this.moveSlide(-1)}>&#10094;</a>
          <div className='slide-container'>
          {items.map((item, index) => {
            return (
              <div key={index} className={cx('slide', currentSlide === index && 'active')}>
                {renderItem({ item })}
              </div>
            );
          })}
          </div>
          <a className="next" onClick={() => this.moveSlide(1)}>&#10095;</a>
        </MainContainer>
        <DotContainer>
            {items.map((item, index) => (
                <div
                    key={index}
                    className={cx('dot', currentSlide === index && 'active')}
                    onClick={() => this.setSlideIndex(index)}
                />
            ))}
        </DotContainer>
      </>
    );
  }
}
