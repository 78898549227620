import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import { mq } from '../common/styles';
import Hero from '../components/Hero';
import PageContent from '../components/PageContent';
import Layout from '../components/Layout'
import Salutation from '../components/Salutation';
import Quote from '../components/Quote';
import TestimonialsWidget from '../components/TestimonialsWidget';
import CallToAction from "../components/CallToAction";

const StyledPage = styled.div`
  .hero-wrapper {
    ${mq.laptop} {
      margin: 0 56px;
    }
  }
  .welcome {
    margin-top: 16px;
    ${mq.laptop} {
      display: flex;
    }
  }
  .img-portrait {
    ${mq.laptop} {
      flex: none;
      width: 42.5%;
      margin-left: 32px;
    }
  }
`;

export function Index() {
  /*
   * Replace the elements below with your own.
   *
   * Note: The corresponding styles are in the ./index.styled-components file.
   */
  return (
    <Layout>
      <StyledPage>
        <div className='hero-wrapper'>
          <Hero />
        </div>
        <PageContent>
          <div className='welcome'>
            <div>
              <Salutation>Welcome!</Salutation>
              <p>
                Ivory & Rust is a client-focused event and wedding planning business in the Mid-Ohio Valley with unique design and rental features and a mission to create the most memorable experiences, suited to your taste and preferred guest experience.
              </p>
              <p>
                Founder, Sarah Schmidt, established Ivory & Rust in 2021, using her knowledge and background assisting clients through their planning process and giving them their dream event. With a knack for hospitality and expertise in creating time-saving and organizational techniques, Sarah will help you design your picture-perfect event with your personality and passions in mind.
              </p>
              <p>
                When it comes to planning the event of your dreams, your venue is one of the most important elements. The “where” sets the tone for planning and stylistic direction. Thankfully, with Sarah’s experience planning both indoor and outdoor events — big and small — she’s no stranger to “making it work.” Her established relationships with vendors are an added bonus for clients and have played a major role in her event planning success. So, clients needing a little extra guidance can lean on Sarah’s go-to vendor options.
              </p>
              <p>
                Planning an event can be stressful, especially when it comes to financials. But you can find comfort in knowing that, no matter what your budget is — six figures or 600 dollars — Sarah will maximize your money to give you the event you and your guests will be talking about for years to come.
              </p>
              <Quote quote='and so the adventure begins...' className='hide-mobile'/>
            </div>
            <div className='img-portrait'>
              <StaticImage layout='constrained' maxWidth={800} src='../images/portraits/vert-waterside.jpeg' alt='Sarah Headshot'/>
              <Quote quote='and so the adventure begins...' className='hide-laptop'/>
            </div>
          </div>
        </PageContent>
        <TestimonialsWidget/>

        <CallToAction
            to='/services'
            primerText='Packages Available'
            actionText='Check our services'
        />
      </StyledPage>
    </Layout>
  );
}

export default Index;
