import * as React from 'react';
import styled from 'styled-components';
import {StaticQuery, graphql, Link} from "gatsby";
import Slideshow from './Slideshow';
import {mq, pageGutters} from "../common/styles";

const testimonialsQuery = graphql`
query {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/testimonials/"}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        excerpt
        id
      }
    }
  }
}
`;


const Container = styled.div`
  ${mq.laptop} {
    margin: 0 ${pageGutters.laptop}px;
  }
  
  h4 {
    padding-top: 12px;
    margin: 0 0 16px 0;
    text-align: center;
  }
`;

const TestimonialCard  = styled.div`
  font-size: 13px;
  .read-more {
    text-align: center;
    font-size: 11px;
  }
`;

const TestimonialsWidget = () => (
    <Container>
        <div>
            <h4>Testimonials</h4>
            <div>
                <StaticQuery
                    query={testimonialsQuery}
                    render={(data) => (
                        <Slideshow
                            items={data.allMarkdownRemark.edges.map(edge => edge.node)}
                            renderItem={({ item }) => (
                                <TestimonialCard>
                                    {item.excerpt}
                                    <div className='read-more'>
                                        <Link to='/testimonials'>Read more</Link>
                                    </div>
                                </TestimonialCard>
                            )}
                        />
                    )}
                />
            </div>
        </div>
    </Container>
);
export default TestimonialsWidget
