import * as React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { mq } from '../common/styles';
import { StaticImage } from "gatsby-plugin-image"

const StyledHero = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  .set {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;  
    justify-content: space-between;
    transition: opacity 1s linear;
    opacity: 1;
    z-index: 2;
  }
  .set-first {
    position: relative;
  }
  .set-hidden {
    z-index: 1;
    opacity: 0.1;
  }
  .item {
    width: 32.5%;
    ${mq.laptop} {
      width: 32%;
    }
  } 
`;

const common = {
  maxWidth: 1200,
  layout: 'fluid',
  //placeholder: 'dominantColor',
};

const sets = [[
  <StaticImage {...common} src='../images/hero/set_a_1.JPG' alt='Cake'/>,
  <StaticImage {...common} src='../images/hero/set_a_2.JPG' alt='Table Flowers'/>,
  <StaticImage {...common} src='../images/hero/set_a_3.JPG' alt='Hanging Bo-ho'/>
], [
  <StaticImage {...common} src='../images/hero/set_b_1.JPG' alt='Happy Couple' />,
  <StaticImage {...common} src='../images/hero/set_b_2.JPG' alt='Dairy Barn Venue'/>,
  <StaticImage {...common} src='../images/hero/set_b_3.JPG' alt='Cake Squirrels'/>
], [
  <StaticImage {...common} src='../images/hero/set_c_1.JPG' alt='Vanity'/>,
  <StaticImage {...common} src='../images/hero/set_c_2.JPG' alt='Table Setting'/>,
  <StaticImage {...common} src='../images/hero/set_c_3.JPG' alt='Happy Couple'/>
]];

/**
 * mq for image layout
 * on timeout or window r
 */

class Hero extends React.Component {
  changeSlideInterval = undefined;
  state = {
    visibleSetIndex: 0,
  };

  componentDidMount() {
    this.changeSlideInterval = setInterval(() => {
      let newIndex = this.state.visibleSetIndex + 1;
      if (newIndex > sets.length - 1) {
        newIndex = 0;
      }
      this.setState({
        visibleSetIndex: newIndex,
      });
    }, 5000);
  }

  componentWillUnmount(): void {
    clearInterval(this.changeSlideInterval);
  }


  render () {
    const { visibleSetIndex } = this.state;


    return (
      <StyledHero>
        {sets.map((set, index) => {
          return (
            <div key={index} className={cx('set', visibleSetIndex !== index && 'set-hidden', index === 0 && 'set-first')}>
              {set.map((Img, imgIndex) => {
                return (
                  <div key={`${index}-${imgIndex}`} className='item'>
                    {Img}
                  </div>
                );
              })}
            </div>
          );
        })}
      </StyledHero>
    );
  }
}

export default Hero;
